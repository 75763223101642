// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbox {
    display: flex;
    flex-direction: column;
    gap: 20px;
     z-index: 9999;
    padding: 20px;
    margin-right: 30px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-height: 80vh;
    margin-left: 280px;
    margin-top: 120px;
    
}
.belownav {
    display: flex;
    position: fixed;
    /* top: 52px; */
    top: 52px;
    left: 250px;
    justify-content: left;
    width: 100%;
    height: 46px;
    /* background-color: var(--color-A); */
  }
.toolbox-section {
    display: flex;
    flex-direction: row;
    gap: 10px;
    
}

.toolbox-section h2 {
    margin: 0;
}

.tool-radio {
    display: flex;
    align-items: center;
}

.tool-radio input[type="radio"] {
    margin-right: 10px;
}

.button {
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.button:hover {
    background-color: #0056b3;
}
.button:hover {
    border: 1px solid #0056b3;
    border-radius: 8px;
}

`, "",{"version":3,"sources":["webpack://./src/pages/assessment/DrawingTools.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;KACR,aAAa;IACd,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;;AAErB;AACA;IACI,aAAa;IACb,eAAe;IACf,eAAe;IACf,SAAS;IACT,WAAW;IACX,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,sCAAsC;EACxC;AACF;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;;AAEb;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;IACzB,kBAAkB;AACtB","sourcesContent":[".toolbox {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n     z-index: 9999;\n    padding: 20px;\n    margin-right: 30px;\n    background-color: #f0f0f0;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    max-height: 80vh;\n    margin-left: 280px;\n    margin-top: 120px;\n    \n}\n.belownav {\n    display: flex;\n    position: fixed;\n    /* top: 52px; */\n    top: 52px;\n    left: 250px;\n    justify-content: left;\n    width: 100%;\n    height: 46px;\n    /* background-color: var(--color-A); */\n  }\n.toolbox-section {\n    display: flex;\n    flex-direction: row;\n    gap: 10px;\n    \n}\n\n.toolbox-section h2 {\n    margin: 0;\n}\n\n.tool-radio {\n    display: flex;\n    align-items: center;\n}\n\n.tool-radio input[type=\"radio\"] {\n    margin-right: 10px;\n}\n\n.button {\n    padding: 8px 16px;\n    background-color: #007bff;\n    color: #fff;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n}\n\n.button:hover {\n    background-color: #0056b3;\n}\n.button:hover {\n    border: 1px solid #0056b3;\n    border-radius: 8px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
