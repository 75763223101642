// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-info-container {
  display: flex;
  height: 100vh;

  
}
.content-container {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}


.add-user-button-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 2rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.placeholder-container {
  flex-grow: 1;
  padding: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/userinfo/UserInfo.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;;;AAGf;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAO;AACT;;;AAGA;EACE,aAAa;EACb,2BAA2B;EAC3B,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;AACf","sourcesContent":[".user-info-container {\n  display: flex;\n  height: 100vh;\n\n  \n}\n.content-container {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n}\n\n\n.add-user-button-container {\n  display: flex;\n  justify-content: flex-start;\n  margin-top: 2rem;\n  margin-left: 1rem;\n  margin-bottom: 1rem;\n}\n\n.placeholder-container {\n  flex-grow: 1;\n  padding: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
